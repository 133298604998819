.team-module-container {
    background: #000000;
    .team-module-headline {
        padding-top: 60px;
    }
    .team-module-text {
        color: #ffffff;
        width: 80%;
        margin: 0 auto;
        padding: 60px 0;
    }
    .team-module-image {

    }
}