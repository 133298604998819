//Default-size auf 12px setzen, falls noch nicht gesetzt
$font-base-size: 16 !default;

//Default-Werte für alle genutzten Variablen, damit die Datei immer kompilierbar ist
$headings: null !default;
$heights_percents: null !default;
$widths_percents: null !default;
$margins: null !default;
$margins_percent: null !default;
$margins_rems: null !default;
$margins: null !default;
$font_sizes: null !default;
$font-weights: null !default;
$font_colors: null !default;
$available_text_decorations: null !default;
$available_vertical_aligns: null !default;
$available_displays: null !default;
$border_widths: null !default;
$available_borders: null !default;
$border_standard_type: null !default;
$border_standard_color: null !default;
$border_colors: null !default;
$background_colors: null !default;
$available_positions: null !default;
$available_positions_absolute: null !default;

/**
 * Hilfsfunktion für Schriftgrößen
 */
@function calculateRem($size) {
  $remSize: $size / $font-base-size;
  @return $remSize * 1rem;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin generate-all(){
  @include gen-all-headings();
  @include gen-font-styles();
  @include gen-text-decorations();
  @include gen-borders();
  @include gen-backgrounds();
}

/**
Parameter: Selector, Schriftart, Schriftgröße in px (rem wird errechnet), Farbe, margin-top, margin-bottom
Nutzung: <h1>...</h1>, <div class="h1">...</div>
Beispiel für Aufbau:
$headings: (
  h1 'Arial' 40 50 'red' 0 0.33,
  h2 'Times New Roman' 30 45 'green' 0 0.33,
);
 */
@mixin gen-all-headings(){
  @if $headings != null{
    @each $heading in $headings{
      #{nth($heading, 1)}, .#{nth($heading, 1)}{
        font-family: #{nth($heading, 2)};
        font-size: #{nth($heading, 3)}px;
        font-size: calculateRem(nth($heading, 3));
        font-weight: #{nth($heading, 8)};
        line-height: #{nth($heading, 4)}px;
        color: #{nth($heading, 5)};
        margin-top: #{nth($heading, 6)}rem;
        margin-bottom: #{nth($heading, 7)}rem;
      }
    }
  }
}

@mixin gen-font-styles(){
  @if $font_sizes != null {
    @each $value in $font_sizes {
      .fs-#{nth($value, 1)} {
        font-size: #{nth($value, 2)}px;
        font-size: calculateRem(nth($value, 2));
      }
      .lh-#{nth($value, 1)} {
        line-height: (nth($value, 2)*1.42857)px;
        line-height: calculateRem(nth($value, 2)*1.42857);
      }
      .hlh-#{nth($value, 1)} {
        height: (nth($value, 2)*1.42857)px;
        height: calculateRem(nth($value, 2)*1.42857);
      }
    }
  }
  @if $font-weights != null {
    @each $value in $font-weights {
      .fw-#{$value} {
        font-weight: #{$value};
      }
    }
  }
  //Variable auf null setzen, falls noch nicht gesetzt
  @if $font_colors != null {
    @each $value in $font_colors {
      .c-#{nth($value, 1)} {
        color: nth($value, 2);
      }
    }
  }
}

/**
Parameter: Selector, Textdekoration
Nutzung: <div class="td-u">...</div>
Beispiel für Nutzung:
$available_text_decorations: (
  'u' 'underline',
  'o' 'overline'
);
 */
@mixin gen-text-decorations(){
  @if $available_text_decorations != null {
    @each $style in $available_text_decorations {
      .td-#{nth($style, 1)} {
        text-decoration: #{nth($style, 2)};
      }
    }
  }
}

/**
AVAILABLE_BORDERS:
  Parameter: Selector, Richtung des Borders
  Nutzung: <div class="bo-t-10">...</div>
  Beispiel für Nutzung:
  $available_borders: (
    't' ('top'),
    'a' ('inline-table')
  );
BORDER_WIDTHS:
  $border_widths: (0, 5, 10, 15);
BORDER_STANDARD_TYPE:
  $border_standard_type: ('solid|dotted|dashed');
BORDER_STANDARD_COLOR:
  $border_standard_color: ('red|blue'|$brand-primary)
*/
@mixin gen-borders(){
  @if $border_widths != null and $available_borders != null and $border_standard_type != null and $border_standard_color != null {
    @each $width in $border_widths {
      @each $item in $available_borders {
        $prefix2: nth($item, 1);
        .bo-#{$prefix2}-#{$width} {
          $val: null;
          @if ($width==0) {
            $val: #{$width};
          } @else {
            $val: #{$width}px $border_standard_type $border_standard_color;
          }
          @if $prefix2==a {
            border: $val;
          } @else {
            $property_suffixes: nth($item, 2);
            @each $property_suffix in $property_suffixes {
              border-#{$property_suffix}: $val;
            }
          }
        }
      }
    }
  }
  @include gen-border-colors();
}

/**
Parameter: Selector, Farbe des Borders
Nutzung: <div class="border-color-blue">...</div>
Beispiel für Nutzung:
$border_colors: (
  'blue' '#00c',
  'red' '#c00'
);
 */
@mixin gen-border-colors(){
  @if $border_colors != null {
    @each $item in $border_colors {
      .bo-color-#{nth($item, 1)} {
        border-color: #{nth($item, 2)};
      }
    }
  }
}

/**
Parameter: Selector, Farbe des Hintergrunds
Nutzung: <div class="bg-blue">...</div>
Beispiel für Nutzung:
$background_colors: (
  'blue' '#00c',
  'red' '#c00'
);
 */
@mixin gen-backgrounds(){
  @if $background_colors != null {
    @each $item in $background_colors {
      .bg-#{nth($item, 1)} {
        background-color: #{nth($item, 2)};
      }
    }
  }
}

/**
Parameter: Selector, Positionsmerkmal
Nutzung: <div class="p-f">...</div>
Beispiel für Nutzung:
$available_positions: (
  'f' 'fixed',
  'a' 'absolute'
);
 */
@mixin gen-positions(){
  @if $available_positions != null {
    @each $style in $available_positions {
      .pos-#{nth($style, 1)} {
        position: #{nth($style, 2)};
      }
    }
  }
}

/**
Parameter: Selector, Positionsmerkmal
Nutzung: <div class="pa-tl">...</div>
Beispiel für Nutzung:
$available_positions_absolute: (
  'tl' ('top', 'left'),
  't' ('top')
);
 */
@mixin gen-positions-absolute(){
  @if $available_positions_absolute != null {
    @each $item in $available_positions_absolute {
      $prefix1: #{nth($item, 1)};
      .pa-#{$prefix1} {
        position: absolute;
        $property_suffixes: nth($item, 2);
        @each $property_suffix in $property_suffixes {
          #{$property_suffix}: 0;
        }
      }
    }
  }
}