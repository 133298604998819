// Highlight Colors
$brand-primary:                 #000000;
$color-ul-grey:                 #F4F4F4;
$color-l-grey:                  #E2E2E2;
$color-ml-grey:                 #DDDDDD;
$color-n-grey:                  #B5B5B5;
$color-md-grey:                 #9B9B9B;
$color-d-grey:                  #575757;
$font-black:                    #222222;
$black:                         #000000;
$white:                         #ffffff;

$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

$font-base-size:          16 !default;
$font-size-12:            12 !default;
$font-size-14:            14 !default;
$font-size-18:            18 !default;
$font-size-20:            20 !default;
$font-size-22:            22 !default;
$font-size-24:            24 !default;
$font-size-26:            26 !default;
$font-size-28:            28 !default;
$font-body:               'Roboto', sans-serif;
$font-headings:           'Roboto', sans-serif;
$font-hand:                   'Kalam', cursive;
$grey-button:                 #636363;

$available_vertical_aligns: (t top, b bottom, m middle);
$available_displays: (b block, ib inline-block, ifx inline-flex, it inline-table, i inline, f flex, t table, tcell table-cell, tcol table-column, trow table-row, n none, li list-item, ri run-in, inh inherit);
$available_text_transforms: (lc lowercase, uc uppercase, cap capitalize);
$available_text_decorations: (ol overline, lt line-through, ul underline);
$available_positions_absolute: (t (top), r (right), b (bottom), l (left), tl (top, left), tr (top, right), bl (bottom, left), br (bottom, right), lr (left, right), tb (top, bottom), a (top, right, bottom, left));

$border_widths: (0, 1, 2, 3, 4, 5);
$border_standard_color: $brand-primary;
$border_standard_type: solid;

$colors: (
        "red" #ff0000,
        "white" $white,
        "ul-grey" $color-ul-grey,
        "l-grey" $color-l-grey,
        "ml-grey" $color-ml-grey,
        "d-grey" #777777,
        "n-grey" $color-n-grey,
        "md-grey" $color-md-grey,
        "grey" #f5f5f5,
        "font-black" $font-black,
        "black" $black);
$font_colors: $colors;
$border_colors: $colors;
$background_colors: $colors;
$button_colors: $colors;

$font_sizes: (
        base $font-base-size,
        12 $font-size-12,
        14 $font-size-14,
        16 $font-base-size,
        18 $font-size-18,
        20 $font-size-20,
        22 $font-size-22,
        24 $font-size-24,
        26 $font-size-26,
        28 $font-size-28
);