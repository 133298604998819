.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-family: $font-body;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}


.btn-circle {
  width: 70px;
  height: 70px;
  margin-top: 15px;
  padding: 7px 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  background: 0 0;
  -webkit-transition: background .3s ease-in-out;
  -moz-transition: background .3s ease-in-out;
  transition: background .3s ease-in-out;
}

.btn-circle.btn-dark {
  border: 2px solid #666;
  color: #666;
}


.btn-circle:hover,
.btn-circle:focus {
  outline: 0;
  color: #fff;
  background: rgba(255,255,255,.1);
}

.btn-circle.btn-dark :hover,
.btn-circle.btn-dark :focus {
  outline: 0;
  color: #999;
  background: #fff;
}

.btn-circle.btn-dark :hover i,
.btn-circle.btn-dark :focus i{
  color: #999;
}

.page-scroll .btn-circle i.animated {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
}

.page-scroll .btn-circle:hover i.animated {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
}