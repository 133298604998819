$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-font-family: "";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

@import "~bootstrap/scss/bootstrap";

$fa-font-path: '~font-awesome/fonts';
@import "~font-awesome/scss/font-awesome.scss";

@import "variables";
@import "utilities-mixins";
@import "theme";

@import "footer";
@import "modules/icon-with-text/icon-with-text";
@import "modules/teaser/teaser";
@import "modules/team/team";
@import "modules/facts/facts";

///*BEGINGENERATED*/
@include generate-all();
///*ENDGENERATED*/


@font-face {
    font-family: "CorporateS";
    src: url("../fonts/Corporate-S-regular-webfont.woff") format("woff"),
    url("../fonts/Corporate-S-regular-webfont.eot") format("eot"),
    url("../fonts/Corporate-S-regular-webfont.ttf") format("ttf");
}

@font-face {
    font-family: "CorporateACon-Reg";
    src: url("../fonts/Corporate-Acon-reg-webfont.woff") format("woff"),
    url("../fonts/Corporate-Acon-reg-webfont.eot") format("eot"),
    url("../fonts/Corporate-Acon-reg-webfont.ttf") format("ttf");
}

html {
    font-size: 100%;
    font-size: #{$font-base-size}px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

.container {
    width: 100%;
    padding-right: 1px;
    padding-left: 1px;
    margin-right: auto;
    margin-left: auto;
    .row {
        margin-right: -1px;
        margin-left: -1px;
        .col-lg-4 {
            @media (min-width: 992px) {
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
            @media (min-width: 1024px) {
                -webkit-box-flex: 0;
                flex: 0 0 33.3333333333%;
                max-width: 33.3333333333%;
            }
        }
    }
    @media (min-width: 480px) {
        max-width: 420px;
    }
    @media (min-width: 768px) {
        max-width: 708px;
    }
    @media (min-width: 1024px) {
        max-width: 964px;
    }
}

.container-fluid {
    padding: 0;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    .container {
        @media screen and (min-width: 1260px) {
            max-width: 1260px;
            padding: 0;
            margin: 0 auto;
        }
        @media screen and (max-width: 1400px) and (min-width: 1260px) {
            max-width: 1160px;
        }
        @media (max-width: 924px) and (min-width: 768px) {
            width: 720px;
        }
        @media (max-width: 767px) and (min-width: 0) {
            margin-left: 30px;
            margin-right: 30px;
            max-width: calc(100% - 60px);
        }
    }
}

body {
  font-family: CorporateS, sans-serif;
  color: $font-black;
  font-size: 1em;
  font-size: #{$font-base-size}px;
  line-height: $line-height-base;
  overflow-x: hidden;
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-l-grey;
  color: $font-black;
  text-align: center;
}

h1 {
    font-family: "CorporateACon-Reg", sans-serif;
    font-size: 70px;
    color: #ffffff;
    &:before {
        background: #fff;
        content: "";
        display: block;
        height: 2px;
        margin-bottom: 12px;
        margin-top: 0;
        margin-left: calc(50% - 60px);
        position: relative;
        width: 120px;
    }
    @media screen and (max-width: 1024px) {
        text-align: center;
        font-size: 30px;
        &:before {
            width: 50px;
            margin-left: calc(50% - 25px);
        }
    }
}

@media screen and (max-width: 1024px) {
    .module-text {
        p {
            text-align: center;
        }
    }
}

.key-visual {
    position: relative;
    padding-top: 62.57%;
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
    }
}
.key-visual-logo {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    @media screen and (max-width: 992px) {
        visibility: hidden;
    }
    img {
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        position: absolute;
        z-index: 10;
    }
}

.background-black {
    background-color: #000000;
}

.no-padding {
    padding: 0;
}