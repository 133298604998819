.teaser-module-container {
    background: #000000;
    position: relative;
    @media screen and(max-width: 1024px) {
        padding-bottom: 200px;
    }
    .teaser-module-background-image {
        position: relative;
        padding-top: 62.57%;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;
        }
    }
    .teaser-module-content-container {
        @media screen and (min-width: 1024px) {
            bottom: 100px;
            height: auto;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 9;
        }
        position: relative;
        padding-top: 100px;

        .teaser-module-content {
            color: #fff;
            font-size: 18px;
            margin-left: 0;
            position: relative;
            width: 680px;
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
            @media screen and (min-width: 1024px) {
                .teaser-module-headline {
                    h1 {
                        &:before {
                            margin-left: 0;
                        }
                    }
                }
            }
            .teaser-module-text {
                margin: 30px 0;
            }
        }
    }
    .teaser-module-button {
        font-family: CorporateS, sans-serif;
        font-size: 22px;
        padding: 0 45px;
        height: 55px;
        color: #000000;
        border-radius: 3px;
        background-color: #ffffff;
        margin-top: 50px;
        text-transform: none;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    .teaser-module-button-mobile {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            position: absolute;
            z-index: 20000;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 30px;
        }
    }
}