footer {
    background-color: white;
    .inner-footer-container {
        height: 400px;
        .footer-links {
            font-family: CorporateS, sans-serif;
            font-size: 20px;
            margin-top: 80px;
            margin-bottom: 50px;
            a {
                color: #ffffff;
                margin: 0 20px;
                text-transform: uppercase;
                &:hover {
                    color: #f0f0f0;
                }
            }
        }
        hr {
            border-top: 1px solid #fff;
        }
    }
    .background-green {
        background-color: #46d483;
    }
}