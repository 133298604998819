.icon-with-text-module-container {
    background: black;
    .icon-with-text-module-headline {
        padding-top: 60px;
    }
    .icon-with-text-module-icon {
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            margin: 0 auto;
        }
    }
    .spacing {
        padding: 45px;
        @media screen and (max-width: 1024px) {
            padding: 40px;
        }
        @media screen and (min-width: 1024px) {
            &:first-of-type {
                padding-left: 0;
                padding-right: 90px;
            }
            &:last-of-type {
                padding-right: 0;
                padding-left: 90px;
            }
        }
        .icon-with-text-module-text {
            margin-top: 45px;
            @media screen and (max-width: 1024px) {
                margin-top: 20px;
            }
            p {
                background-color: #000000 !important;
                color: #ffffff;
            }
        }
    }
}