.navbar {
    position: absolute;
    padding: 0;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    .navbar-collapse {
        margin-top: 20px;
        .navbar-nav {
            margin: 0 auto;
            .nav-item {
                margin: 0 40px;
            }
        }
    }
    &.navbar-custom {
        .navbar-nav {
            .nav-link {
                color: #ffffff;
                font-size: 34px;
                text-align: center;
                font-family: CorporateS, sans-serif;
                font-weight: normal;
                padding: 0;
            }
        }
        .navbar-header {
            position: absolute;
            margin-top: 25px;
            right: 20px;
            z-index: 30;
        }
        .navbar-toggler-icon {
            padding: 25px !important;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='3' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
        .navbar-toggler {
            border-color: transparent;
        }
    }
}